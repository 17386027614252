import {Component} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {AdBannerComponent} from './adSense/adSense.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    HomeComponent,
    AdBannerComponent],
  standalone: true,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Casa Faletto';
}
