import {AfterViewInit, Component} from '@angular/core';

interface AdsbygoogleWindow extends Window {
  adsbygoogle: { push: ({}: {}) => void }[];
}

declare var window: AdsbygoogleWindow;

@Component({
  selector: 'app-ad-banner',
  template: `
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="ca-pub-9299238200652157"
         data-ad-slot="1234567890"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  `,
  standalone: true
})
export class AdBannerComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    try {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({
        push({}: {}): void {
        }
      });
    } catch (e) {
      console.error('error');
    }
  }
}
